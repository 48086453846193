import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../../assets/css/animate.css'



const InstagramSlider = (props) => {

    const handleOnClickImg = (permalink) => {
        window.open(permalink, "_blank")
    }

    return (
        <>
            <OwlCarousel 
                items={1}
                loop = {true}
                nav = {true}
                autoplay = {false}
                autoplayHoverPause= {true}
            > 
                {
                    props.data !== undefined && props.data?.map((item, index) => {
                        return <img src={item.media_url} alt={item.id} title={item.id} key={index} onClick={() => handleOnClickImg(props.permalink)} />
                    })
                }
            </OwlCarousel>
        </>
    );
}


export default InstagramSlider;