import React from 'react';
import { Link } from 'react-router-dom'
import { FaEnvelope,FaLinkedinIn,FaInstagram,FaFacebookF,FaYoutube } from "react-icons/fa";

const MenuBar = (props) => {

    return (
        <div className="openmenu">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 menubar">
                        <div className="row navbar">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <li className="li-style">
                                    <Link onClick={props.onClick} to="/interest/">Explore Interest</Link>
                                </li>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <li className="li-style">
                                    <Link onClick={props.onClick} to="/about-us" >About LE</Link>
                                </li>
                                
                                {/* <li className="li-style">
                                    <Link onClick={props.onClick} to="/comments" >Experiential Comments</Link>
                                </li> */}
                            </div>
                        </div>
                        <div className="row navbar">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <li className="li-style">
                                    <Link onClick={props.onClick} to="/destinations" >Discover Destinations</Link>
                                </li>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <li className="li-style">
                                    <Link onClick={props.onClick} to="contact-us" >Contact With US</Link>
                                </li>
                                {/* <li className="li-style">
                                    <Link onClick={props.onClick} to="/about-us" >About LE</Link>
                                </li> */}
                            </div>
                        </div>
                        <div className="row navbar">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <li className="li-style">
                                    <Link onClick={props.onClick} to="/experiences" >Exceptional Experiences</Link>
                                </li>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <li className="li-style">
                                    <Link onClick={props.onClick} to={"/instagram"} >Instagram</Link>
                                </li>
                            </div>
                        </div>
                        <div className="row social-navbar">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                {
                                    props.data !== undefined ?
                                    <ul className="social-list">
                                        { props.data.companyFacebookUrl ? <li>
                                            <a href={props.data.companyFacebookUrl} target="_blank" title="Facebook"><FaFacebookF /></a>
                                        </li> : null }
                                        { props.data.companyInstagramUrl ? <li>
                                            <a href={props.data.companyInstagramUrl} target="_blank" title="Instagram"><FaInstagram /></a>
                                        </li> : null }
                                        { props.data.companyLinkedinUrl ? <li>
                                            <a href={props.data.companyLinkedinUrl} target="_blank" title="Linkedin"><FaLinkedinIn /></a>
                                        </li> : null }
                                        { props.data.companyYoutubeUrl ? <li>
                                            <a href={props.data.companyYoutubeUrl} target="_blank" title="Youtube"><FaYoutube /></a>
                                        </li> : null }
                                    </ul>: null
                                }
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                { props.data !== undefined ?
                                    <li>
                                        <a href={`mailto:${props.data.offices[0].officeEmail}`} title="Mail"><FaEnvelope /> {props.data.offices[0].officeEmail}</a>
                                    </li> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 padd4side">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuBar;