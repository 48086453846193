import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PageLaoder from '../../atom/loader/pageLaoder';
import { Link } from 'react-router-dom';
import InstagramSlider from '../../atom/InstagramSlider';
import { FaImages,FaVideo } from 'react-icons/fa'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'
import './mediacss.scss'
import { useSelector } from 'react-redux';

let instagramToken = process.env.REACT_APP_INSTAGRAM_TOKEN

const InstagramHomeSlider = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    }

    const organiz = useSelector((state) => state.home.organization);

    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_url,children{media_url,thumbnail_url},thumbnail_url,timestamp,media_type,permalink&access_token=${instagramToken}`)
        .then((response) => {
            setIsLoading(false)
            setPosts(response.data.data);
        })
        .catch((error) => {
            setIsLoading(false)
            console.error(error);
        });
    }, []);

    const handleOnClickImg = (permalink) => {
        window.open(permalink, "_blank")
    }

    if(isLoading){
        return <PageLaoder />
    }

    return (<>
            <a className='userid' href={`${organiz ? organiz.companyInstagramUrl : "#"}`} target='_blank'>
                <h2>#luxuryescapadesbyyusuf</h2> 
            </a>
            <div className='instagram-section'>
                    <Slider {...settings} >
                    {
                        posts.map(post => {
                            return <div className='insta-card' key={post.id}>
                                {post.media_type === 'VIDEO' ? (
                                    <div className='card-body'>
                                        <figure>
                                            <video poster={post.thumbnail_url} onClick={() => handleOnClickImg(post.permalink)} />
                                        </figure>
                                    </div>
                                ) : (
                                    <div className='card-body'>
                                        <figure>
                                            <img src={post.media_url} alt={post.caption} onClick={() => handleOnClickImg(post.permalink)} />
                                        </figure>
                                    </div>    
                                )}
                            <a href={post.permalink} target="_blank" rel="noopener noreferrer">View on Instagram</a>
                        </div>
                        })
                    }
                    </Slider>
            </div>
        </>
    );
}

export default InstagramHomeSlider;
