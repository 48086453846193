import React,{useEffect,useState,useRef} from 'react';
import moment from 'moment'
import { FaVolumeUp, FaPlay, FaTimes } from 'react-icons/fa'

const VideoPlayer = ({post, handleBackOnInsta}) => {

    const [isMuted, setIsMuted] = useState(false);
    const [isPlay, setIsPlay] = useState(true);
    const videoRef = useRef(null);

    useEffect(() =>{
        const video = videoRef.current;
        console.log("useEffect js", video)
    },[videoRef])

    const toggleMute = () => {
        const video = videoRef.current;
        if (video.muted) {
        video.muted = false;
        setIsMuted(false);
        } else {
        video.muted = true;
        setIsMuted(true);
        }
    };

    const toggleVideo = () => {
      const video = videoRef.current;
  
      if (video.paused) {
        video.play();
        setIsPlay(true)
      } else {
        video.pause();
        setIsPlay(false)
      }
    }; 


    return (
        <div className='player_section'>
            <div className='over_layout' onClick={handleBackOnInsta}></div>
            <div className='video_player'>
            <div className='close_insta' onClick={handleBackOnInsta}><FaTimes /></div>
                <figure>
                    <video ref={videoRef} autoPlay 
                        poster={post.thumbnail_url} 
                        onClick={toggleVideo} 
                        onEnded={() => setIsPlay(false)}
                    >
                        <source src={post.media_url} type="video/mp4"  />
                    </video>
                    <div className={`${isPlay ? "d-none" : "push"}`}  onClick={toggleVideo}>
                        <FaPlay />
                    </div>
                    <div className={`${!isMuted ? "volume" : "volume unmute"}`} onClick={toggleMute}>
                        <FaVolumeUp />
                    </div>
                    <figcaption>
                        <div className='post_captions'>
                            <a href={`${post.permalink}`} target= "_blank">
                                <h5>{post.username}</h5>
                            </a>
                            <div className='captions'>
                                <h6>{post.username} : <strong>{moment(post.timestamp).format('ll')}</strong></h6>
                                {
                                    post.caption.split('\n').map((line, index) => {
                                        return <p key={index} className={`lbl${index}`}dangerouslySetInnerHTML={{__html: line}} />
                                    })
                                }
                            </div>
                            <div className='view_insta'>
                                <a href={`${post.permalink}`} target= "_blank">View on Instagram</a>
                            </div>
                        </div>
                    </figcaption>
                </figure>
            </div>
        </div>
    )
}


export default VideoPlayer;