import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PageLaoder from '../../atom/loader/pageLaoder';
import { Link } from 'react-router-dom';
import InstagramSlider from '../../atom/InstagramSlider';
import { FaEye, FaInstagram, FaImages,FaVideo } from 'react-icons/fa'
import { GrLinkNext, GrLinkPrevious  } from "react-icons/gr";
import InstagramBanner from '../../../assets/images/instagram-log.png'
import './style.scss'
import './mediacss.scss'
import VideoPlayer from './videoPlayer';
import { useSelector } from 'react-redux';

let instagramToken = process.env.REACT_APP_INSTAGRAM_TOKEN

const InstagramPosts = () => {

    const organiz = useSelector((state) => state.home.organization);

    const [posts, setPosts] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [item, setItem] = useState(null)
    const [paging, setPaging] = useState(null)

    useEffect(() => {
        axios.get(`https://graph.instagram.com/me/media?fields=id,username,caption,media_url,children{media_url,thumbnail_url},thumbnail_url,timestamp,media_type,permalink&access_token=${instagramToken}`)
        .then((response) => {
            const { data, paging} = response.data
            setIsLoading(false)
            setPosts(data);
            setPaging(paging);
        })
        .catch((error) => {
            setIsLoading(false)
            console.error(error);
        });
    }, []);

    const handleNextPage = (urls) => {
        axios.get(`${urls}`)
        .then((response) => {
            const { data, paging} = response.data
            setIsLoading(false)
            setPosts(data);
            setPaging(paging);
        })
        .catch((error) => {
            setIsLoading(false)
            console.error(error);
        });
    }

    const handleVideoPlayer = (post) => {
        setItem(post)
    }

    const handleBackOnInsta = () => {
        setItem(null)
    }

    if(isLoading){
        return <PageLaoder />
    }

    return (
        <div className='instagram-page'>
            <div className='hero_banner'>
                <div className="item">
                    <figure>
                        <img className="desktop" src={InstagramBanner} alt={"Instagram"} title={"Instagram"} />
                        <figcaption>
                            <p>Instagram</p>
                            <div className='logo'>
                                <FaInstagram />
                            </div>
                        </figcaption>
                    </figure>
                </div>
            </div>
           
            <div className='wrapper'>
                <section className='container'>
                    <div className="instagram-section">
                        <ul className="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">Instagram</li>
                        </ul>

                        <a className='userid' href={`${organiz ? organiz.companyInstagramUrl : null}`} target='_blank'>
                            <h2>luxuryescapadesbyyusuf</h2> 
                        </a>

                        <div className="insta-box">
                            {
                                posts.map(post => {
                                
                                    if(post.children){
                                        const {data} = post.children
                                        return <div className='insta-post' key={post.id}>
                                            {post.media_type === 'VIDEO' ? (
                                                <figure>
                                                    <video controls poster={post.thumbnail_url}>
                                                        <source src={post.media_url} type="video/mp4" />
                                                    </video>
                                                </figure>
                                            ) : (
                                                <figure>
                                                    <InstagramSlider data = {data} permalink={post.permalink} />
                                                </figure>
                                            )}
                                        <a href={post.permalink} target="_blank" rel="noopener noreferrer"><FaImages /></a>
                                    </div>
                                    }else{
                                        return <div className='insta-post' key={post.id}>
                                            {post.media_type === 'VIDEO' ? (
                                                <figure onClick={() => handleVideoPlayer(post)}>
                                                    <video poster={post.thumbnail_url} />
                                                </figure>
                                            ) : (
                                                <figure>
                                                    <img src={post.media_url} alt={post.caption} />
                                                </figure>
                                            )}
                                        <a href={post.permalink} target="_blank" rel="noopener noreferrer"><FaVideo /></a>
                                    </div>
                                    }
                                })
                            }
                        </div>
                        {item ? <VideoPlayer post={item} handleBackOnInsta={handleBackOnInsta} /> : null }
 
                        { paging ?
                            <div className='nav-button'>
                                <button className={!paging["previous"] ? 'previous disable' : 'previous' } disabled={!paging["previous"]} onClick={() => handleNextPage(paging['previous'])}><GrLinkPrevious /></button>
                                <button className={!paging["next"] ? 'next disable' : 'next' } disabled={!paging["next"]} onClick={() => handleNextPage(paging['next'])}><GrLinkNext /></button>
                            </div> : null
                        }
                    </div>
                </section>
            </div>
        </div>
    );
}

export default InstagramPosts;
