import React from 'react';
import OwlCarousel from 'react-owl-carousel';  
import { useNavigate, Link } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../../assets/css/animate.css'
let imageUrl = process.env.REACT_APP_IMAGE_URL;



const HeroBanner = (props) => {
const navigate = useNavigate();


    return (
        <div className="hero_banner">
            <OwlCarousel 
                items={props.items}
                loop = {props.loop}
                margin= {props.margin}
                animateOut= {props.animateOut}
                autoplay = {props.outoplay}
                autoplayHoverPause= {props.autoplayHoverPause}
                autoplaySpeed = {props.speed}
                responsiveClass = {props.responsiveClass}
            > 
                {
                    props.banner.length && props.banner.map((item, index) => {
                        // console.log("item.bannerMediaUrl", item.bannerMediaUrl)
                        return (<div className="item" key={index}>
                            <figure>
                                <img className="desktop" src={`${imageUrl}/images/${item.bannerDesktopImagePath}`} alt={item.bannerImageAlt} title={item.bannerImageTitle} />
                                <img className="tablet" src={`${imageUrl}/images/${item.bannerTabletImagePath}`} alt={item.bannerImageAlt} title={item.bannerImageTitle} />
                                <img className="mobile" src={`${imageUrl}/images/${item.bannerMobileImagePath}`} alt={item.bannerImageAlt} title={item.bannerImageTitle} /> 
                                <figcaption className='dest'>
                                    <h1>{item.bannerName}</h1>
                                    <div dangerouslySetInnerHTML={{__html: item.bannerDescription}} />
                                    <a className="btn-primary"
                                        href={`${item.bannerMediaUrl !== null ? item.bannerMediaUrl : "#"}`}
                                    >Explore Now</a>
                                </figcaption>
                            </figure>
                        </div>
                        )
                    })
                }
            </OwlCarousel>
        </div>
    );
}


export default HeroBanner;